import styled, { css } from 'styled-components';

import { colors, transition } from 'theme/theme';

export const CheckboxHolder = styled.div<{ $hasError?: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: start;
  min-height: 35px;
  border-radius: 8px;
  ${(props) =>
    props.$hasError &&
    css`
      border: solid 1px ${colors.alertRed};
      box-shadow: 0 0 0 1px ${colors.alertRed};
      padding-right: 15px;
      padding-left: 15px;
    `};
`;

export const StyledLabel = styled.label`
  padding-left: 12px;
  cursor: pointer;
`;

export const StyledCheckbox = styled.input<{ $isTabbing?: boolean }>`
  position: absolute;
  top: -2px;
  left: -2px;
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  padding: 2px;
  margin: 0;
  background: none;
  border: 0;
  cursor: pointer;
  appearance: none;

  &::-ms-check {
    display: none;
  }
  ${(props) =>
    !props.$isTabbing &&
    css`
      outline: 0;
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: auto;
    `}
`;

export const CheckmarkHolder = styled.div<{
  $checked?: boolean;
  $disabled?: boolean;
}>`
  position: absolute;
  background: ${colors.inputGrey};
  box-shadow: 0 0 0 1px rgb(0 0 0 / 20%) inset;
  ${(props) =>
    props.$disabled &&
    css`
      background: ${colors.selectionGrey};
      box-shadow: 0 0 0 1px rgb(0 0 0 / 0%) inset;
    `}
  ${(props) =>
    props.$checked &&
    css`
      background: ${colors.brandBlue};
      box-shadow: 0 0 0 1px rgb(0 0 0 / 0%) inset;
    `}
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all ${transition};
  border-radius: 2px;
`;

export const ToggleCheckmarkHolder = styled.div`
  width: 16px;
  height: 16px;

  svg {
    display: block;
  }
`;

export const Toggle = styled.div<{
  $disabled?: boolean;
  $checked?: boolean;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  transition: all ${transition};
  border-radius: 12px;
  background: ${colors.elementGrey};
  opacity: 1;

  ${(props) =>
    props.$disabled &&
    css`
      opacity: 0.75;
    `}

  ${(props) =>
    props.$checked &&
    css`
      background: ${colors.brandBlue};
    `}

  &::before {
    content: '';
    left: 2px;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    ${(props) =>
      props.$disabled &&
      css`
        opacity: 0.75;
      `}
  }
  ${ToggleCheckmarkHolder} {
    left: 5px;
  }
  &::before,
  ${ToggleCheckmarkHolder} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
      translateX(${(props) => (props.$checked ? 16 : 0)}px);
    transition: all ${transition};
  }
`;
