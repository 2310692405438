import { ReactNode } from 'react';

import { Box } from 'components/Box';
import { AnimatedCheckmark } from 'components/Icon/AnimatedCheckmark';
import { Body } from 'components/Text/Body';
import { TabContext } from 'containers/TabContext';

import { CheckmarkHolder, Holder, Input } from './LargeCheckbox.styled';

type Props = {
  'data-qa-id'?: string;
  id?: string;
  name?: string;
  label: ReactNode;
  checked: boolean;
  hasError?: boolean;
  onChange: (arg0: boolean) => void;
  disabled?: boolean;
  fullWidth?: boolean;
};

export function LargeCheckbox({
  id,
  name,
  label,
  checked,
  hasError,
  onChange,
  disabled,
  fullWidth,
  ...props
}: Props) {
  return (
    <Holder $checked={checked} $disabled={disabled} $fullWidth={fullWidth}>
      <Box top={0} left={0} position="absolute" width={1} height="100%">
        <TabContext.Consumer>
          {(isTabbing) => (
            <Input
              type="checkbox"
              name={name}
              id={id}
              checked={checked}
              onChange={(e) => {
                e.stopPropagation();
                onChange(e.target.checked);
              }}
              $isTabbing={isTabbing}
              disabled={disabled}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            />
          )}
        </TabContext.Consumer>
      </Box>

      <CheckmarkHolder $checked={checked}>
        {checked && <AnimatedCheckmark size={16} colorName="brandBlue" />}
      </CheckmarkHolder>

      <Box
        component={Body}
        pointerEvents="none"
        // @ts-expect-error fix me
        htmlFor={id}
        checked={checked}
        lineHeight={1.375}
        fontSize={18}
        ml={16}
        mb={0.5}
      >
        {label}
      </Box>
    </Holder>
  );
}
