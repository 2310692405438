import styled from 'styled-components';

import { colors, transition } from 'theme/theme';

export const Input = styled.input<{ $isTabbing?: boolean }>`
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  cursor: pointer;

  && {
    appearance: none;
  }
  ${(props) =>
    !props.$isTabbing &&
    `
    &:focus {
      outline: 0;
    }
  `}
`;

export const CheckmarkHolder = styled.div<{ $checked?: boolean }>`
  background: ${colors.white};
  color: ${(props) => (props.$checked ? 'white' : colors.darkContentGrey)};
  box-shadow: 0 0 0 1px
    ${(props) => (props.$checked ? 'white' : 'rgba(0,0,0,0.5)')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  transition: all ${transition};
`;

export const Holder = styled.div<{
  $checked?: boolean;
  $disabled?: boolean;
  $fullWidth?: boolean;
}>`
  background: ${(props) =>
    props.$checked ? colors.brandBlue : colors.inputGrey};
  color: ${(props) => (props.$checked ? 'white' : colors.darkContentGrey)};
  width: ${(props) => (props.$fullWidth ? '100%' : 'fit-content')};
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 20px 24px;
  border-radius: 8px;
  transition: all ${transition};
  ${(props) =>
    props.$disabled
      ? `
  opacity: 0.7;
  transition: all ${transition}, opacity ${transition} 0.5s;
  * {
    cursor: not-allowed;
  }
  `
      : `
  * {
    cursor: pointer;
  }
  &:hover {
    background: ${colors.brandBlue} !important;
    color: white !important;
    ${CheckmarkHolder} {
      box-shadow: 0 0 0 1px white !important;
    }
  }
  `}
`;
